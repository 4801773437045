* {
    box-sizing: border-box;
}

.logo {
    position: absolute;
    left: 0;
    top: 64px;
    width: 64px;
}

header {
    height: 420px;
    background: url('./bg.jpg');
    background-size: cover;
    background-position: center; 
    box-shadow: 0 0 400px rgba(0,0,0,.2);
}

.header--container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 450px 64px 0 64px;
    font-size: 28px;
}

h1 {
    color: white;
    font-weight: normal;
}

article {
    padding: 64px; 
    background: white;
    max-width: 1170px;
    min-height: 500px;
    margin: 0 auto;
    transform: translate(0, -64px);
}