.wrapper {
    margin-bottom: 32px;
}

.item {
    padding: 16px 0;
}

.name {
    color: var(--red);
}

.list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
}

.list li {
    margin-top: 12px;
    flex: 1;
}

.list span:nth-child(1) {
    color: var(--grey);
    font-size: 11px;
    display: block;
    margin-bottom: 3px;
}

.list span:nth-child(2) {
    color: black;
    font-size: 14px;
    display: block;
}

.link {
    display: block;
    margin-top: 12px;
    color: var(--grey);
    font-size: 11px;
}

.more {
    background: #fafafa;
    padding: 32px;
    transform: translate(-32px);
    width: calc(100% + 64px);
    font-size: 12px;
    border-bottom: solid 1px #E5E5E5;
    margin-top: 12px;
    position: relative;
}

.more span {
    color: #C4C4C4;
    display: block;
    margin-bottom: 9px;
}

.more a {
    color: #C4C4C4;
}

.ellipsis {
    position: absolute;
    background: white;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: solid 1px #E5E5E5;
    left: 50%;
    margin-left: -10px;
    transform: translate(0, -10px);
    text-align: center;
    color: white;
}

.ellipsis::before {
    content: '...';
    color: #C4C4C4;
}  
