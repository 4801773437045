body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
    box-sizing: border-box;
}

.logo {
    position: absolute;
    left: 0;
    top: 64px;
    width: 64px;
}

header {
    height: 420px;
    background: url(/static/media/bg.502a9679.jpg);
    background-size: cover;
    background-position: center; 
    box-shadow: 0 0 400px rgba(0,0,0,.2);
}

.header--container {
    max-width: 1170px;
    margin: 0 auto;
    padding: 450px 64px 0 64px;
    font-size: 28px;
}

h1 {
    color: white;
    font-weight: normal;
}

article {
    padding: 64px; 
    background: white;
    max-width: 1170px;
    min-height: 500px;
    margin: 0 auto;
    -webkit-transform: translate(0, -64px);
            transform: translate(0, -64px);
}

.Search_input__1Ykvw {
    border-radius: 6px;
    outline: none;
    border: solid #ccc 1px;
    font-size: 24px;
    padding: 8px 40px 8px 16px;
    width:  50%;
    min-width: 350px;
    margin-bottom: 16px;
}

.Search_checkbox__nrU6q {
    -webkit-transform: translate(0, -2px);
            transform: translate(0, -2px);
}

.Search_options__3LOhA {
    margin-bottom: 64px;
}

.Search_wrapper__28n_y {
    display: flex;
    flex-direction: row;
}

.Search_clear__1lrdj {
    color: #ccc;
    font-size: 24px;
    -webkit-transform: translate(-32px, 12px);
            transform: translate(-32px, 12px);
    height: 100%;
}

.Search_clear__1lrdj:hover {
    cursor: pointer;
}

:root {
    --grey: #747474;
    --light-grey: #c6c6c6;
    --red: red;
}

.Result_title__3ccjt {
    color: #c6c6c6;
    color: var(--light-grey);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 11px;
}

.PeopleResult_wrapper__3AlR2 {
    margin-bottom: 32px;
}

.PeopleResult_item__1Hx61 {
    padding: 16px 0;
}

.PeopleResult_name__1K8Qw {
    color: var(--red);
}

.PeopleResult_list__UNaVR {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
}

.PeopleResult_list__UNaVR li {
    margin-top: 12px;
    flex: 1 1;
}

.PeopleResult_list__UNaVR span:nth-child(1) {
    color: var(--grey);
    font-size: 11px;
    display: block;
    margin-bottom: 3px;
}

.PeopleResult_list__UNaVR span:nth-child(2) {
    color: black;
    font-size: 14px;
    display: block;
}

.PeopleResult_link__rdEhI {
    display: block;
    margin-top: 12px;
    color: var(--grey);
    font-size: 11px;
}

.PeopleResult_more__2pn_p {
    background: #fafafa;
    padding: 32px;
    -webkit-transform: translate(-32px);
            transform: translate(-32px);
    width: calc(100% + 64px);
    font-size: 12px;
    border-bottom: solid 1px #E5E5E5;
    margin-top: 12px;
    position: relative;
}

.PeopleResult_more__2pn_p span {
    color: #C4C4C4;
    display: block;
    margin-bottom: 9px;
}

.PeopleResult_more__2pn_p a {
    color: #C4C4C4;
}

.PeopleResult_ellipsis__1IFXm {
    position: absolute;
    background: white;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: solid 1px #E5E5E5;
    left: 50%;
    margin-left: -10px;
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
    text-align: center;
    color: white;
}

.PeopleResult_ellipsis__1IFXm::before {
    content: '...';
    color: #C4C4C4;
}  

.PagesResult_item__dfHPS {
    padding: 16px 0;
}

.PagesResult_item__dfHPS em {
    font-weight: bold;
    font-style: normal;
}

.PagesResult_title__q6t9N {
    color: var(--red);
    display: block;
    margin-bottom: 12px;
}

.PagesResult_url__1K_xj {
    display: block;
    margin-bottom: 12px;
    color: var(--grey);
}

.PagesResult_link__1ErK7 {
    text-decoration: none;
}

.PagesResult_innerClass__2_sTR {
    list-style: none;
    margin: 64px 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}
.PagesResult_activeClass__2T-fa {
    background: red;
    color: white;
}
.PagesResult_activeLinkClass__3XbbG {
}
.PagesResult_itemClass__2l0C_ {
    width: 32px;
    border: solid 1px #e3e3e3;
    margin-left: -1px;
    text-align: center;
    padding: 6px;
    font-size: .8rem;
}
.PagesResult_itemClass__2l0C_ a {
    text-decoration: none;
    color: inherit;
}
.PagesResult_itemClass__2l0C_:hover {
    cursor: pointer;
    background: #f1f1f1;
}
.PagesResult_itemClassFirst__37dWR {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.PagesResult_itemClassPrev__3mf8X {
}
.PagesResult_itemClassNext__2ynrD {
}
.PagesResult_itemClassLast__CYp6- {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.PagesResult_disabledClass__1TOw_ {
}

