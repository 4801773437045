:root {
    --grey: #747474;
    --light-grey: #c6c6c6;
    --red: red;
}

.title {
    color: var(--light-grey);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 11px;
}
