
.input {
    border-radius: 6px;
    outline: none;
    border: solid #ccc 1px;
    font-size: 24px;
    padding: 8px 40px 8px 16px;
    width:  50%;
    min-width: 350px;
    margin-bottom: 16px;
}

.checkbox {
    transform: translate(0, -2px);
}

.options {
    margin-bottom: 64px;
}

.wrapper {
    display: flex;
    flex-direction: row;
}

.clear {
    color: #ccc;
    font-size: 24px;
    transform: translate(-32px, 12px);
    height: 100%;
}

.clear:hover {
    cursor: pointer;
}
