.item {
    padding: 16px 0;
}

.item em {
    font-weight: bold;
    font-style: normal;
}

.title {
    color: var(--red);
    display: block;
    margin-bottom: 12px;
}

.url {
    display: block;
    margin-bottom: 12px;
    color: var(--grey);
}

.link {
    text-decoration: none;
}

.innerClass {
    list-style: none;
    margin: 64px 0;
    padding: 0;
    display: flex;
    flex-direction: row;
}
.activeClass {
    background: red;
    color: white;
}
.activeLinkClass {
}
.itemClass {
    width: 32px;
    border: solid 1px #e3e3e3;
    margin-left: -1px;
    text-align: center;
    padding: 6px;
    font-size: .8rem;
}
.itemClass a {
    text-decoration: none;
    color: inherit;
}
.itemClass:hover {
    cursor: pointer;
    background: #f1f1f1;
}
.itemClassFirst {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.itemClassPrev {
}
.itemClassNext {
}
.itemClassLast {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.disabledClass {
}
